<template>
  <div>
    <b-modal
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative"
      size="xl"
      hide-footer
      hide-header
      :title="title"
      no-close-on-backdrop
      :visible="true"
      centered
      @hide="closeGuide"
      @close="closeGuide"
    >
      <div class="header text-18 font-weight-bold">
        {{ title }}
        <span
          class="btn-close text-primary cursor-pointer position-absolute text-32 icon-X"
          @click="closeGuide"
        >
        </span>
      </div>
      <div class="main text-center">
        {{ content }}
      </div>
      <div class="footer">
        <b-button
          variant="primary"
          size="md"
          class="d-block mx-auto text-14"
          style="width: 150px; line-height: 18px"
          @click="closeGuide"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '說明',
    },
    content: {
      type: String,
      default: '',
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: '知道了',
    },
  },
  methods: {
    closeGuide() {
      this.$emit('closeGuide');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-dialog {
  width: 300px;
  margin: 0.5rem auto;
  .modal-body {
    padding: 0;
  }
  .btn-close {
    top: 14px;
    right: 6px;
  }
  .header {
    padding: 15px 16px;
    border-bottom: 1px solid $line-1;
    color: $font-grayscale-1;
  }
  .footer {
    padding: 8px 16px;
    border-top: 1px solid $line-1;
  }
  .main {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    padding: 16px 32px;
    line-height: 28px;
  }
}
</style>
