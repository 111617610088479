const NAMESPACE_PREFIX = 'ARGUMENT_HELPER_';

/**
 * 調參助手
 */
let currentNamespace = null;
export const ArgumentHelper = {
  setNS(namespace = 'basic') {
    let args = {};

    if (currentNamespace) {
      // 第二次 init
      args = window[currentNamespace];
      delete window[currentNamespace];
    }

    currentNamespace = `${NAMESPACE_PREFIX}${namespace}`;
    window[currentNamespace] = args;
  },
  set(key, value) {
    window[currentNamespace][key] = value;
  },
  get(key, defaultValue) {
    return window[currentNamespace][key] != null
      ? window[currentNamespace][key]
      : defaultValue;
  },
  list() {
    console.log({ ...window[currentNamespace] });
  },
};

// initialize
ArgumentHelper.setNS(); // assert init namespace

// put on window
window.ArgumentHelper = ArgumentHelper;
