/* eslint-disable require-jsdoc */
import influence from '@sabaki/influence';
import deadstones from 'deadstones';
deadstones.useFetch('./deadstones_bg.wasm');
import {sgfToArray, getGroupAndLiberties} from '@/lib/wgo/board';

/**
 * get influence by MCTS and radiance method
 * @param {String} sgf go game information
 * @returns {Array} influence array
 */
async function getRadianceInfluence(sgf) {
  const boardArray = sgfToArray(sgf);
  // replace 'X' by 1, 'O' by -1, '.' by 0
  const numberBoardArray = boardArray.map((row) => {
    return row.map((cell) => {
      if (cell === 'X') {
        return 1;
      } else if (cell === 'O') {
        return -1;
      } else {
        return 0;
      }
    });
  });
  const deadstoneList = await deadstones.guess(numberBoardArray, 1000);
  for (let i = 0; i < deadstoneList.length; i++) {
    const deadStone = deadstoneList[i];
    numberBoardArray[deadStone[1]][deadStone[0]] = 0;
  }
  const influenceArray = influence.map(numberBoardArray);
  return influenceArray;
}

/**
 * Bouzy’s method
 * @param {Array} numberBoardArray 棋盤矩陣
 * @param {Number} dilationCount 膨脹次數
 * @param {Number} erosionCount 侵蝕次數
 * @returns {Array} influence array
 */
function doBouzyMethod(numberBoardArray, dilationCount = 5, erosionCount = 10) {
  // Do 5 dilation
  let bouzyArray = [];
  for (let i = 0; i < numberBoardArray.length; i++) {
    bouzyArray.push([]);
    for (let j = 0; j < numberBoardArray[i].length; j++) {
      bouzyArray[i].push(numberBoardArray[i][j]);
    }
  }
  for (let i = 0; i < dilationCount; i++) {
    bouzyArray = doDilation(numberBoardArray, bouzyArray);
  }
  // Do 21 erosion
  for (let i = 0; i < erosionCount; i++) {
    bouzyArray = doErosion(numberBoardArray, bouzyArray);
  }
  return bouzyArray;
}

/**
 * 檢查此點是否在範圍內
 * @param {Object} position 要檢查的點
 * @param {Number} length 範圍長度
 * @returns {Boolean} 是否在範圍內
 */
function isInBounds(position, length) {
  const {x, y} = position;
  return x >= 0 && x < length && y >= 0 && y < length;
}

/**
 * 接近棋子數量
 * @param {Array} boardArray 棋盤矩陣
 * @param {Object} point 測試點
 * @param {String} player 玩家
 * @returns {Number} 接近棋子數量
 */
function getApproachWeights(boardArray, point, player = 'black') {
  let approachWeights = 0;
  const distance = Math.min(
    boardArray.length - point.x,
    boardArray[point.x].length - point.y,
    point.x + 1,
    point.y + 1
  );

  const EMPTY = 0;
  const BLACK = 128;
  const WHITE = -128;
  const D1WEIGHT = 1.8;
  const D2WEIGHT = 1.5;
  const D3WEIGHT = 1.2;
  const D4WEIGHT = 0.9;
  const {x, y} = point;

  let shift = 1;
  const found = [];
  for (let i = 0; i < 4; i++) {
    found[i] = false;
  }

  while (shift < 5) {
    if (distance < 4 && shift > 1) return 3;
    const directions = [
      {x: x - shift, y},
      {x: x + shift, y},
      {x, y: y - shift},
      {x, y: y + shift},
    ];
    let hasBlack = false;
    let hasWhite = false;

    for (let i = 0; i < directions.length; i++) {
      if (!isInBounds(directions[i], boardArray.length)) continue;
      const {x, y} = directions[i];
      if (boardArray[x][y] === EMPTY || found[i] === true) continue;

      if (boardArray[x][y] === BLACK) {
        found[i] = true;
        hasBlack = true;
        if (hasWhite) {
          approachWeights =
            player === 'black' ? approachWeights : -approachWeights;
          return shift > 1 ? approachWeights : 0;
        }

        if (shift === 1) {
          approachWeights += D1WEIGHT;
        } else if (shift === 2) {
          approachWeights += D2WEIGHT;
        } else if (shift === 3) {
          approachWeights += D3WEIGHT;
        } else if (shift === 4) {
          approachWeights += D4WEIGHT;
        }
      }

      if (boardArray[x][y] === WHITE) {
        found[i] = true;
        hasWhite = true;
        if (hasBlack) {
          approachWeights =
            player === 'black' ? approachWeights : -approachWeights;
          return shift > 1 ? approachWeights : 0;
        }

        if (shift === 1) {
          approachWeights -= D1WEIGHT;
        } else if (shift === 2) {
          approachWeights -= D2WEIGHT;
        } else if (shift === 3) {
          approachWeights -= D3WEIGHT;
        } else if (shift === 4) {
          approachWeights -= D4WEIGHT;
        }
      }
    }

    shift++;
  }

  const diagonals = [
    {x: x - 1, y: y - 1},
    {x: x - 1, y: y + 1},
    {x: x + 1, y: y - 1},
    {x: x + 1, y: y + 1},
  ];

  for (const diagonal of diagonals) {
    const {x, y} = diagonal;
    if (boardArray[x][y] === BLACK) {
      approachWeights += 1.5;
    } else if (boardArray[x][y] === WHITE) {
      approachWeights -= 1.5;
    }
  }

  approachWeights = player === 'black' ? approachWeights : -approachWeights;
  return approachWeights;
}

/**
 * do dilation
 * @param {Array} boardArray 棋盤矩陣
 * @param {Array} bouzyArray bouzy矩陣
 * @returns {Array} influence array
 */
function doDilation(boardArray, bouzyArray) {
  const newBouzyArray = [];
  for (let i = 0; i < boardArray.length; i++) {
    newBouzyArray.push([]);
    for (let j = 0; j < boardArray[i].length; j++) {
      const blackWeight =
        getApproachWeights(boardArray, {x: i, y: j}, 'black') >= 3 ? 1 : 0.5;
      const whiteWeight =
        getApproachWeights(boardArray, {x: i, y: j}, 'white') >= 3 ? 1 : 0.5;

      let sum = 0;
      for (let k = -1; k < 2; k++) {
        for (let l = -1; l < 2; l++) {
          if (
            i + k >= 0 &&
            i + k < boardArray.length &&
            j + l >= 0 &&
            j + l < boardArray[i].length
          ) {
            if (Math.abs(k + l) === 1) {
              if (bouzyArray[i + k][j + l] > 0 && bouzyArray[i][j] >= 0) {
                sum += blackWeight;
              } else if (
                bouzyArray[i + k][j + l] < 0 &&
                bouzyArray[i][j] <= 0
              ) {
                sum -= whiteWeight;
              }
            }
          }
        }
      }
      newBouzyArray[i].push(bouzyArray[i][j] + sum);
    }
  }
  return newBouzyArray;
}

/**
 * do erosion
 * @param {Array} boardArray 棋盤矩陣
 * @param {Array} bouzyArray bouzy矩陣
 * @returns {Array} influence array
 */
function doErosion(boardArray, bouzyArray) {
  const newBouzyArray = [];
  for (let i = 0; i < boardArray.length; i++) {
    newBouzyArray.push([]);
    for (let j = 0; j < boardArray[i].length; j++) {
      const blackWeight =
        getApproachWeights(boardArray, {x: i, y: j}, 'black') >= 3 ? 0.75 : 1;
      const whiteWeight =
        getApproachWeights(boardArray, {x: i, y: j}, 'white') >= 3 ? 0.75 : 1;

      // const weights = 1.5 / distance;
      let sum = 0;
      for (let k = -1; k < 2; k++) {
        for (let l = -1; l < 2; l++) {
          if (
            i + k >= 0 &&
            i + k < boardArray.length &&
            j + l >= 0 &&
            j + l < boardArray[i].length
          ) {
            if (Math.abs(k + l) === 1) {
              if (bouzyArray[i + k][j + l] <= 0 && bouzyArray[i][j] > 0) {
                sum -= blackWeight;
              } else if (
                bouzyArray[i + k][j + l] >= 0 &&
                bouzyArray[i][j] < 0
              ) {
                sum += whiteWeight;
              }
            }
          }
        }
      }
      let newValue;
      if (bouzyArray[i][j] > 0) {
        newValue = bouzyArray[i][j] + sum < 0 ? 0 : bouzyArray[i][j] + sum;
      } else if (bouzyArray[i][j] < 0) {
        newValue = bouzyArray[i][j] + sum > 0 ? 0 : bouzyArray[i][j] + sum;
      } else {
        newValue = 0;
      }
      newBouzyArray[i].push(newValue);
    }
  }
  return newBouzyArray;
}

/**
 * 取得靠近的棋子顏色
 * @param {Array} boardArray 棋盤矩陣
 * @param {Object} point 測試落點
 * @param {String} direction 方向
 * @param {Number} recursion 遞迴深度
 * @returns {Number} 靠近的棋子顏色
 */
function getApproachColor(boardArray, point, direction, recursion) {
  let x = point.x;
  let y = point.y;
  if (direction === 'up') {
    x -= 1;
  } else if (direction === 'down') {
    x += 1;
  } else if (direction === 'left') {
    y -= 1;
  } else if (direction === 'right') {
    y += 1;
  }
  if (x < 0 || x >= boardArray.length || y < 0 || y >= boardArray[x].length) {
    return 0;
  }
  if (boardArray[x][y] > 0) {
    return 1;
  } else if (boardArray[x][y] < 0) {
    return -1;
  } else {
    if (recursion > 0) {
      return getApproachColor(boardArray, {x, y}, direction, recursion - 1);
    } else {
      return 0;
    }
  }
}

/**
 * get influence by MCTS and morphology
 * @param {String} sgf go game information
 * @param {Number} dilationCount 膨脹次數
 * @param {Number} erosionCount 侵蝕次數
 * @returns {Array} influence array
 */
async function getMorphologyInfluence(
  sgf,
  dilationCount = 5,
  erosionCount = 10
) {
  const boardArray = sgfToArray(sgf);
  // replace 'X' by 1, 'O' by -1, '.' by 0
  const numberBoardArray = boardArray.map((row) => {
    return row.map((cell) => {
      if (cell === 'X') {
        return 1;
      } else if (cell === 'O') {
        return -1;
      } else {
        return 0;
      }
    });
  });
  const originalDeadstoneList = await deadstones.guess(numberBoardArray, 10000);
  const deadstoneList = [];
  for (let i = 0; i < originalDeadstoneList.length; i++) {
    const position = {
      x: originalDeadstoneList[i][0],
      y: originalDeadstoneList[i][1],
    };
    const player =
      boardArray[position.y][position.x] === 'X' ? 'black' : 'white';
    const group = getGroupAndLiberties(
      numberBoardArray,
      position,
      player
    ).group;
    for (const stone of group) {
      if (!deadstoneList.includes(stone)) {
        deadstoneList.push(stone);
      }
    }
  }

  const newBoardArray = boardArray.map((row) => {
    return row.map((cell) => {
      if (cell === 'X') {
        return 128;
      } else if (cell === 'O') {
        return -128;
      } else {
        return 0;
      }
    });
  });

  for (let i = 0; i < deadstoneList.length; i++) {
    const deadStone = deadstoneList[i];
    newBoardArray[deadStone.y][deadStone.x] = 0;
  }

  const bouzyResult = doBouzyMethod(newBoardArray, dilationCount, erosionCount);
  // modify bouzyResult value in low row and col
  const boardSize = newBoardArray.length;
  const isInCorner = (x, y) =>
    [0, 1, 2, boardSize - 3, boardSize - 2, boardSize - 1].includes(x) &&
    [0, 1, 2, boardSize - 3, boardSize - 2, boardSize - 1].includes(y);
  for (let i = 0; i < bouzyResult.length; i++) {
    for (let j = 0; j < bouzyResult[i].length; j++) {
      if (
        i === 0 ||
        i === bouzyResult.length - 1 ||
        j === 0 ||
        j === bouzyResult[i].length - 1
      ) {
        bouzyResult[i][j] = bouzyResult[i][j] * 4;
      }
      if (
        i === 1 ||
        i === bouzyResult.length - 2 ||
        j === 1 ||
        j === bouzyResult[i].length - 2
      ) {
        bouzyResult[i][j] = bouzyResult[i][j] * 2;
      }
      if (isInCorner(i, j)) {
        if (bouzyResult[i][j] === 0) {
          const approachArray = [
            getApproachColor(bouzyResult, {x: i, y: j}, 'up', 2),
            getApproachColor(bouzyResult, {x: i, y: j}, 'down', 2),
            getApproachColor(bouzyResult, {x: i, y: j}, 'left', 2),
            getApproachColor(bouzyResult, {x: i, y: j}, 'right', 2),
          ];

          if (
            approachArray.reduce((a, b) => a + b) >= 2 &&
            approachArray.every((color) => color !== -1)
          ) {
            bouzyResult[i][j] = 10;
          } else if (
            approachArray.reduce((a, b) => a + b) <= -2 &&
            approachArray.every((color) => color !== 1)
          ) {
            bouzyResult[i][j] = -10;
          }
        }
      }
    }
  }
  const influenceArray = bouzyResult.map((row) => {
    return row.map((cell) => {
      if (cell > 8) {
        return 1;
      } else if (cell > 4) {
        return 0.7;
      } else if (cell > 0) {
        return 0.5;
      } else if (cell < -8) {
        return -1;
      } else if (cell < -4) {
        return -0.7;
      } else if (cell < 0) {
        return -0.5;
      } else {
        return 0;
      }
    });
  });
  return influenceArray;
}

export {getRadianceInfluence, getMorphologyInfluence};
