<template>
  <div ref="wrapper" class="wrapper">
    <div ref="chessboard-wrapper" class="chessboard-wrapper">
      <div class="chessboard-container text-center">
        <div
          :id="playerId"
          class="chessboard"
          :style="isLocked ? {'pointer-events': 'none'} : {}"
        ></div>
        <div class="board-image" :style="chessboardImageStyle">
          <slot></slot>
        </div>
      </div>
      <div v-if="isCorrectModalShow" class="isCorrect">
        <span
          :class="isCorrect ? 'icon-Colorful-correct' : 'icon-Colorful-error'"
        >
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </div>
    </div>
    <b-row
      v-show="!hideToolBar"
      class="chessboard-toolbar mt-2 mx-0 mt-md-3"
      :class="{
        'fixed-height': !isModalJudgementResultShow && toolbar !== 'board',
        'mt-3': toolbar === 'checkVariation',
      }"
    >
      <b-col cols="12" class="p-0 position-relative">
        <ChessboardToolbar
          :mode="chessboardToolbarMode"
          :step-type="stepType"
          :current-step="currentStep"
          :max-step="maxStep"
          :question="question"
          :has-support-game="hasSupportGame"
          :has-influence="hasInfluence"
          :has-test-play="hasTestPlay"
          :has-judge="hasJudge"
          :support-count="supportCount"
          :kifu-review-game="kifuReviewGame"
          @item-click="onToolbarClick"
          @move-chess="moveChess"
          @check-answer-tab="checkAnswerTab"
          @on-click-video="onClickVideo"
          @step-change="goto"
          @sticker-select="$emit('sticker-select', $event)"
        ></ChessboardToolbar>
      </b-col>
    </b-row>
    <b-row
      v-if="isModalJudgementResultShow && $device.isMobile"
      class="modal-judgement-result w-100 m-0"
    >
      <b-col cols="12" class="p-0 position-relative">
        <b-card
          border-variant="secondary"
          class="card-shadow position-relative"
        >
          <div class="d-flex flex-column align-items-center m-3">
            <p class="judgement-result mb-3 text-16">
              {{ getWinningWayText() }}
            </p>
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                variant="secondary"
                size="md"
                class="mr-3"
                @click="checkJudgementResult('cancel')"
              >
                {{ $t('取消') }}
              </b-button>
              <b-button
                variant="primary"
                size="md"
                @click="checkJudgementResult('confirm')"
              >
                {{ $t('確定') }}
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <modal-message-box
      v-if="isModalMessageBoxShow"
      :result-content="$t('確定要認輸？')"
      :buttons="messageBoxButtons"
      @on-item-click="onModalMessageBoxClick"
    >
    </modal-message-box>
  </div>
</template>

<script>
import WgoHelper from 'wgo-helper';
import {getMorphologyInfluence} from '@/lib/wgo/influence';
import {InfluenceController} from '@/lib/wgo/utils';

import GameConfig from '@/json/game.json';
import GameHelper from '@/lib/wgo/gameHelper.js';
import ChessboardToolbar from '@/components/Chessboard/ChessboardToolbar.vue';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';
import delay from '@/lib/base/delay.js';
import filters from '@/lib/base/filters.js';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    ChessboardToolbar,
    ModalMessageBox,
  },
  props: {
    mode: {
      type: String,
      default: 'board',
      validator: (val) => {
        return ['game', 'tsume', 'board', 'pvp'].includes(val);
      },
    },
    boardSize: {
      type: [Number, String],
      default: 9,
    },
    sgf: {
      type: String,
      default: '',
    },
    isTsumeStart: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isQuestionNext: {
      type: Boolean,
      default: false,
    },
    toolbar: {
      type: String,
      default: '',
    },
    isVerification: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    hideToolBar: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasSupportGame: {
      type: Boolean,
      default: false,
    },
    hasInfluence: {
      type: Boolean,
      default: false,
    },
    hasTestPlay: {
      type: Boolean,
      default: true,
    },
    hasJudge: {
      type: Boolean,
      default: true,
    },
    isSupportStep: {
      type: Boolean,
      default: false,
    },
    supportCount: {
      type: Number,
      default: 0,
    },
    kifuReviewGame: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isMounted: false,
      playerId: `player-${Math.random().toString(36).slice(2)}`,
      wgoHelper: null,
      influenceController: null,
      isAiThinking: false,
      chessboardToolbarMode: this.toolbar,
      isScoreModalShow: false,
      position: null,
      doubleCheckPosition: null,
      isModalMessageBoxShow: false,
      isModalJudgementResultShow: false,
      messageBoxButtons: [
        {
          variant: this.$isJungo ? 'primary' : 'secondary',
          value: 'cancle',
          content: '取消',
        },
        {
          variant: this.$isJungo ? 'secondary' : 'primary',
          value: 'confirm',
          content: '確定',
        },
      ],
      theme: {
        blackStoneColor: '#4a4141',
        gridLinesColor: '#6c4c1f',
        starColor: '#6c4c1f',
        starSize(board) {
          if (board.stoneRadius < 12) {
            return board.stoneRadius / 8 + 0.5;
          } else {
            return board.stoneRadius / 8 + 4;
          }
        },
        stoneSize(board) {
          return Math.min(board.fieldWidth, board.fieldHeight) / 2.2;
        },
        mCharCode: 59686,
        mColor: '#ff855e',
      },
      action: null,
      scoreResult: null,
      winningWay: '',
      isCorrectModalShow: false,
      isCorrect: false,
      chessType: '',
      currentStep: 0,
      maxStep: 0,
      storeStep: 0,
      currentBoardSize: this.boardSize,
      suggestPathMove: null,
      isTestPlay: false,
      testPlayState: {}, // 試下狀態保存
      isInfluence: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
    user() {
      return this.$store.state.user;
    },
    isTeacher() {
      return this.$store.getters['user/isTeacher'];
    },
    userColor() {
      return this.$store.state.aiGame.userColor;
    },
    chessboardImageStyle() {
      const backgroundColor = '#f4d99b';
      const borderColor = this.isTestPlay ? '#FF855E' : '#ffc069';
      const borderStyle = this.$device.isMobile
        ? `inset 0 0 0 4px ${borderColor}, 0 0 0 4px ${borderColor}`
        : `0 0 0 8px ${borderColor}`;
      return {
        backgroundColor,
        borderRadius: '8px',
        'box-shadow': this.hasBorder || this.isTestPlay ? borderStyle : 'none',
      };
    },
    turnColor() {
      if (this.wgoHelper) {
        return this.wgoHelper.turnColor;
      } else {
        return 'black';
      }
    },
    stepType() {
      if (this.wgoHelper) {
        return this.wgoHelper.showStepType;
      } else {
        return '';
      }
    },
    laoziSetting() {
      return this.user.userData.config.laoziSetting === 'confirm';
    },
    hasOpenCoordinates() {
      return this.user.userData.config.hasOpenCoordinates;
    },
  },
  watch: {
    'wgoHelper.sgf'() {
      this.currentBoardSize = this.wgoHelper.boardWidth;
      this.$emit('onSgfChange', this.wgoHelper.sgf);
    },
    currentBoardSize() {
      this.$emit('onBoardSizeChange', this.currentBoardSize);
    },
    currentStep() {
      this.$emit('current-step-change', this.currentStep);
    },
    maxStep() {
      this.$emit('max-step-change', this.maxStep);
    },
    chessboardToolbarMode() {
      this.$emit('chessboard-toolbar-mode-change', this.chessboardToolbarMode);
    },
    turnColor(newValue) {
      if (newValue && this.isScoreModalShow) {
        this.isScoreModalShow = false;
        this.chessboardToolbarMode = this.toolbar;
      }
    },
    isTsumeStart(newValue) {
      if (newValue) {
        this.initialTsumePlayer();
        this.questionNext();
      }
    },
    isQuestionNext(newValue) {
      if (newValue) {
        this.questionNext();
      }
    },
    laoziSetting(newValue) {
      if (this.wgoHelper && this.wgoHelper.editable) {
        this.wgoHelper.editable.isNoBoldGrid = !newValue;
        if (!newValue) {
          this.cancelDoubleCheckPosition();
        }
      }
    },
    hasOpenCoordinates() {
      if (this.wgoHelper) {
        this.wgoHelper.toggleCoordinates();
      }
    },
    variation(newValue) {
      if (newValue.length !== 0) {
        this.storeStep = this.currentStep;
        this.checkStep('prev');
        this.currentStep = 0;
        this.wgoHelper.gotoVariantion(newValue);
        this.wgoHelper.showStep('all');
        this.checkStep('next');
      } else {
        this.wgoHelper.loadSgf(this.sgf);
        this.currentStep = this.storeStep;
        this.wgoHelper.showStep('normal');
        this.wgoHelper.goto(this.storeStep);
      }
    },
    toolbar(newValue) {
      if (newValue) {
        this.chessboardToolbarMode = this.toolbar;
      }
    },
  },
  created() {
    const store = this.$store;
    this.action = {
      getUserColor() {
        return store.state.aiGame.userColor;
      },
      setSgf(sgf) {
        store.commit('aiGame/setSgf', sgf);
      },
      updateGame({move}) {
        return store.dispatch('aiGame/updateGame', {move});
      },
      nextMove() {
        return store.dispatch('aiGame/nextMove');
      },
      resign() {
        return store.dispatch('aiGame/resign');
      },
      getJudgement() {
        return store.dispatch('aiGame/getJudgement');
      },
      confirmJudgement() {
        return store.dispatch('aiGame/confirmJudgement');
      },
      overGameData() {
        store.commit('aiGame/overGameData');
      },
    };
  },
  mounted() {
    this.isMounted = true;
    if (this.mode === 'game') {
      this.wgoHelper = new GameHelper(
        this.mode,
        this.playerId,
        this.sgf || `(;CA[big5]SZ[${this.currentBoardSize}])`,
        this.action,
        this.theme
      );
    } else if (this.mode === 'pvp') {
      this.wgoHelper = new WgoHelper(
        'game',
        this.playerId,
        this.sgf || `(;CA[big5]SZ[${this.currentBoardSize}])`,
        {theme: this.theme}
      );
    } else {
      this.wgoHelper = new WgoHelper(
        this.mode,
        this.playerId,
        this.sgf || `(;CA[big5]SZ[${this.currentBoardSize}])`,
        {theme: this.theme}
      );
    }
    this.influenceController = new InfluenceController(
      this.wgoHelper,
      getMorphologyInfluence
    );
    if (this.hasOpenCoordinates) {
      this.wgoHelper.toggleCoordinates();
    }

    this.wgoHelper.on('update', async () => {
      this.maxStep = this.isTestPlay
        ? this.wgoHelper.completeStep
        : this.wgoHelper.mainStep;
      this.currentStep = this.wgoHelper.step;
      this.$emit('board-grid-change', this.wgoHelper.boardGrid);
      this.$emit('cap-count-change', this.wgoHelper.captureCount);
      this.$emit('turn-color-change', this.wgoHelper.turnColor);
      this.$emit('complete-step-change', this.wgoHelper.player.kifuReader.step);
      await this.$nextTick();
      if (!this.isSupportStep) {
        this.isInfluence = false;
        this.influenceController.removeInfluence();
      }
    });
    if (this.mode === 'game') {
      this.initialGamePlayer();
    } else if (this.mode === 'tsume') {
      this.wgoHelper.setConfig({displayVariations: false});
      this.wgoHelper.setFrozen(true);
      this.wgoHelper.on('play', () => {
        this.$playSound('play');
      });
    } else if (this.mode === 'pvp') {
      this.$emit('step-type-change', this.wgoHelper.showStepType);
      this.wgoHelper.last();
    } else {
      this.wgoHelper.showAnswerMarkups();
      this.toolbar !== 'checkAnswer' && this.toolbar !== 'board'
        ? this.wgoHelper.last()
        : null;
      this.wgoHelper.setFrozen(true);
      this.$emit('cap-count-change', this.wgoHelper.captureCount);
    }
    this.wgoHelper.editable.isNoBoldGrid = !this.laoziSetting;
    this.wgoHelper.setBeforeClick((x, y) => {
      this.beforeLaozi(x, y);
    });
  },
  methods: {
    initialGamePlayer() {
      this.$emit('board-grid-change', this.wgoHelper.boardGrid);
      this.$emit('cap-count-change', this.wgoHelper.captureCount);
      this.$emit('turn-color-change', this.wgoHelper.turnColor);
      this.$emit('step-type-change', this.wgoHelper.showStepType);
      this.$emit('complete-step-change', this.wgoHelper.player.kifuReader.step);

      this.wgoHelper.on('play', () => {
        this.wgoHelper.clearAllMarkups('MONO');
        this.wgoHelper.clearAllMarkups('SCR');
        this.$playSound('play');
      });
      this.wgoHelper.on('AiPass', () => {
        this.$emit('ai-pass');
      });
      this.wgoHelper.on('AiThinkStart', () => {
        this.isAiThinking = true;
      });
      this.wgoHelper.on('AiThinkOver', () => {
        this.isAiThinking = false;
      });
      this.wgoHelper.on('gameOverAndGetItem', ({isWin, winningWay}) => {
        this.$emit('game-over', {isWin, winningWay});
        if (this.isVerification) {
          this.$playSound('finishVerification');
        } else {
          this.$playSound(isWin ? 'win' : 'lose');
        }
      });
      this.wgoHelper.gameStart();
    },
    initialTsumePlayer() {
      this.wgoHelper.on('correct', async () => {
        if (!this.wgoHelper.player.frozen) {
          this.isCorrect = true;
          this.wgoHelper.setFrozen(true);
          await delay(300);
          this.onQuestionDone(true);
        }
      });
      this.wgoHelper.on('incorrect', async () => {
        if (!this.wgoHelper.player.frozen) {
          this.isCorrect = false;
          this.wgoHelper.setFrozen(true);
          await delay(300);
          this.onQuestionDone(false);
        }
      });
      this.wgoHelper.on('playerLock', async (value) => {
        this.$emit('is-player-lock', value);
      });
    },
    onQuestionDone(isCorrect) {
      const userAnswer = this.wgoHelper.getAnswerResult();
      this.isCorrectModalShow = true;
      this.$emit('update-tsume-question', {isCorrect, userAnswer});
      this.$playSound(isCorrect ? 'correct' : 'incorrect');
    },
    questionNext() {
      this.isCorrectModalShow = false;
      const sgf = this.question.sgf;
      this.wgoHelper.loadSgf(sgf);
      this.wgoHelper.clearNextLabel();
      this.wgoHelper.setFrozen(false);
      if (this.question.category === 'MARKUP') {
        this.chessType = 'markup';
        this.wgoHelper.setConfig({
          stoneStyle: 'M',
        });
      } else {
        this.chessType = '';
        this.wgoHelper.setConfig({
          stoneStyle: null,
        });
      }
    },
    // about chessboardtoolbar
    onToolbarClick(event) {
      switch (event) {
        case 'handTriangle':
          this.wgoHelper.showStep('all');
          this.$emit('step-type-change', this.wgoHelper.showStepType);
          break;
        case 'handAll':
          this.wgoHelper.showStep('normal');
          this.$emit('step-type-change', this.wgoHelper.showStepType);
          break;
        case 'judge':
          if (this.mode === 'pvp') {
            this.$emit('judge');
            return;
          }
          if (this.turnColor !== this.userColor)
            return this.$Message.warning(this.$t('還沒輪到你哦'));
          if (this.isAiThinking)
            return this.$Message.warning(
              this.$t('AI計算中') + '，' + this.$t('請耐心等待') + '～'
            );
          if (this.isScoreModalShow)
            return this.$Message.warning(this.$t('請先確認算輸贏結果'));
          const scoreStepLimit =
            GameConfig.scoreStepLimit[this.currentBoardSize];
          if (this.wgoHelper.step < scoreStepLimit) {
            this.$Message.warning(
              `${scoreStepLimit}${this.$t('手以上才能申請算輸赢哦')}`
            );
          } else {
            this.getInfluence();
          }
          break;
        case 'flag':
          this.isModalMessageBoxShow = true;
          BackEvent.addEvent(() => {
            this.onModalMessageBoxClick('cancel');
          });
          break;
        case 'moveCancle':
          this.cancelDoubleCheckPosition();
          break;
        case 'moveConfirm':
          BackEvent.popEvent();
          const position = this.wgoHelper.editable._last_mark;
          if (
            this.wgoHelper.isValid(
              this.doubleCheckPosition.x,
              this.doubleCheckPosition.y
            ) ||
            this.chessType === 'markup'
          ) {
            const checkPosition = position || this.doubleCheckPosition;
            if (this.chessType === 'markup') {
              this.wgoHelper.addMarkup({
                x: checkPosition.x,
                y: checkPosition.y,
                type: 'M',
              });
            } else {
              this.wgoHelper.play(checkPosition);
            }
            this.cancelDoubleCheckPosition();
          }
          break;
        case 'supportGame':
          this.$emit('support-game');
          break;
        case 'testPlay':
          this.isInfluence = false;
          this.influenceController.removeInfluence();
          this.testPlay();
          break;
        case 'influence':
          this.isInfluence = !this.isInfluence;
          this.isInfluence
            ? this.influenceController.draw()
            : this.influenceController.removeInfluence();
          break;
        case 'toggleCoordinates':
          this.wgoHelper.toggleCoordinates(true);
          break;
        case 'pass':
          if (this.mode !== 'pvp' && this.turnColor !== this.userColor)
            return this.$Message.warning(this.$t('還沒輪到你哦'));
          this.wgoHelper.pass();
          break;
      }
    },
    moveChess(direction) {
      const size = this.wgoHelper.boardWidth;
      const originPosition = this.wgoHelper.editable._last_mark;
      let {x, y} = originPosition;
      switch (direction) {
        case 'top':
          y -= 1;
          break;
        case 'down':
          y += 1;
          break;
        case 'left':
          x -= 1;
          break;
        case 'right':
          x += 1;
          break;
        default:
          return;
      }
      if (x >= size || y >= size || x < 0 || y < 0) return;
      this.doubleCheckPosition = {x, y};
      this.wgoHelper.editable.setHoverStonePosition(x, y);
      if (!this.wgoHelper.isValid(x, y)) {
        this.wgoHelper.editable._last_mark = Object.assign(
          {},
          originPosition,
          this.doubleCheckPosition
        );
      }
    },
    cancelDoubleCheckPosition() {
      BackEvent.clearEvents('moveCancle');
      this.wgoHelper.editable.clearHoverStone();
      this.doubleCheckPosition = null;
      this.chessboardToolbarMode = this.toolbar;
    },
    async getInfluence() {
      this.$store.commit('env/setIsLoading', true);
      this.chessboardToolbarMode = '';
      this.wgoHelper.setFrozen(true);
      try {
        const result = await this.wgoHelper.getJudgement();
        this.$store.commit('env/setIsLoading', false);
        if (result.resultError) {
          this.$Message.warning(this.$t(`${result.resultError}`), 1000 * 5);
          this.isScoreModalShow = true;
          this.wgoHelper.setFrozen(false);
        } else {
          this.isScoreModalShow = true;
          this.showJudgementResult(result);
        }
      } catch (error) {
        this.$Message.error(error);
      }
    },
    checkAnswerTab(value, index) {
      switch (value) {
        case 'myAnswer':
          this.showMyAnswer();
          break;
        case 'question':
          this.showQuestion();
          break;
        case 'answer':
          this.showAnswer(index);
          break;
      }
    },
    onClickVideo(videoId) {
      this.$emit('on-click-video', videoId);
    },
    showMyAnswer() {
      const _question = JSON.parse(JSON.stringify(this.question));
      if (!_question.userAnswer) {
        this.wgoHelper.loadSgf(_question.sgf);
        return;
      }
      this.wgoHelper.loadSgf(_question.userAnswer.sgf);
      this.wgoHelper.showAnswerMarkups();
      this.wgoHelper.setFrozen(false);
      this.wgoHelper.showStep('all');
      this.wgoHelper.last();
      this.wgoHelper.setFrozen(true);
    },
    showQuestion() {
      this.wgoHelper.hideAnswerMarkups();
      this.wgoHelper.loadSgf(this.sgf);
    },
    showAnswer(index) {
      if (index) {
        this.wgoHelper.loadSgf(this.question.currentAnswer[index]);
      } else {
        this.wgoHelper.loadSgf(this.question.sgf);
      }
      this.wgoHelper.showAnswerMarkups();
      this.wgoHelper.setFrozen(false);
      this.wgoHelper.showStep('all');
      this.wgoHelper.last();
      this.wgoHelper.setFrozen(true);
    },
    goto(step) {
      this.hideReviewHand();
      this.wgoHelper.setFrozen(false);
      this.wgoHelper.goto(step);
      if (!this.isTestPlay) this.wgoHelper.setFrozen(true);
    },
    async checkStep(event) {
      this.hideReviewHand();
      this.wgoHelper.setFrozen(false);
      switch (event) {
        case 'prev':
          this.wgoHelper.prev();
          break;
        case 'next':
          this.wgoHelper.next();
          break;
        case 'first':
          this.wgoHelper.first();
          break;
        case 'next-ten':
          this.wgoHelper.next10();
          break;
        case 'prev-ten':
          this.wgoHelper.prev10();
          break;
        case 'last':
          this.wgoHelper.last();
          break;
      }
      if (!this.isTestPlay) this.wgoHelper.setFrozen(true);
    },
    // 算輸贏結果Modal
    showJudgementResult(result) {
      this.isModalJudgementResultShow = true;
      this.scoreResult = result;
      this.winningWay = result.winningWay;
      this.$emit('on-judge', {
        isModalJudgementResultShow: true,
        winningWay: this.getWinningWayText(),
      });
      BackEvent.addEvent(() => {
        this.checkJudgementResult('cancel');
      });
    },
    checkJudgementResult(value) {
      BackEvent.popEvent();
      if (value === 'confirm') {
        if (this.isAiThinking) return;
        this.wgoHelper.confirmJudgement({
          winningWay: this.getWinningWayText(),
        });
      } else if (value === 'cancel') {
        this.isModalJudgementResultShow = false;
        this.chessboardToolbarMode = 'game';
        this.isScoreModalShow = false;
        this.wgoHelper.setFrozen(false);
        this.wgoHelper.clearInfluenceMarkup();
        this.$emit('on-judge', {
          isModalJudgementResultShow: false,
          winningWay: this.getWinningWayText(),
        });
      }
    },
    onModalMessageBoxClick(value) {
      BackEvent.popEvent();
      this.isModalMessageBoxShow = false;
      if (value === 'confirm') {
        if (this.mode !== 'pvp') {
          this.wgoHelper.resign();
        }
        this.$emit('resign');
      }
    },
    beforeLaozi(x, y) {
      if (this.laoziSetting && !this.isTestPlay) {
        this.wgoHelper.editable.isNoBoldGrid = false;
        if (!this.wgoHelper.player.frozen) {
          this.wgoHelper.editable.setHoverStonePosition(x, y);
        }
        if (
          !this.wgoHelper.player.frozen &&
          (this.wgoHelper.isValid(x, y) || this.chessType === 'markup')
        ) {
          this.chessboardToolbarMode = 'check2Chess';
          BackEvent.addEvent(() => {
            this.cancelDoubleCheckPosition();
          }, 'moveCancle');
          this.wgoHelper.editable.frozenHoverStone();
          this.doubleCheckPosition = {x, y};
        }
      } else {
        this.wgoHelper.editable.isNoBoldGrid = true;
        if (this.chessType === 'markup' && !this.wgoHelper.player.frozen) {
          this.wgoHelper.addMarkup({x, y, type: 'M'});
        } else {
          this.wgoHelper.play({x, y});
        }
      }
    },
    loadSgf(sgf) {
      this.wgoHelper.loadSgf(sgf);
    },
    // 覆盤顯示問題手或正解
    showReviewHand(reviewHandPathMove, type) {
      this.wgoHelper.showStep('');
      if (type === 'question') {
        this.wgoHelper.setFrozen(false);
        this.wgoHelper.goto(this.currentStep);
        this.wgoHelper.setFrozen(true);

        const questionHandPosition = this.wgoHelper.player.kifuReader.node.move;
        this.wgoHelper.removeMarkup({
          x: questionHandPosition.x,
          y: questionHandPosition.y,
        });
        this.wgoHelper.addMarkup({
          x: questionHandPosition.x,
          y: questionHandPosition.y,
          type: 'MA',
        });
        this.wgoHelper.removeMarkup({
          x: reviewHandPathMove.x,
          y: reviewHandPathMove.y,
          type: 'M',
        });
      } else if (type === 'suggest') {
        this.suggestPathMove = reviewHandPathMove;
        this.wgoHelper.setFrozen(false);
        this.wgoHelper.prev();
        this.wgoHelper.play({x: reviewHandPathMove.x, y: reviewHandPathMove.y});
        this.wgoHelper.addMarkup({
          x: reviewHandPathMove.x,
          y: reviewHandPathMove.y,
          type: 'M',
        });
        this.wgoHelper.setFrozen(true);
      } else if (type === 'excellent') {
        this.wgoHelper.removeMarkup({
          type: 'LB',
          x: reviewHandPathMove.x,
          y: reviewHandPathMove.y,
        });
        this.wgoHelper.addMarkup({
          x: reviewHandPathMove.x,
          y: reviewHandPathMove.y,
          type: 'LB',
          text: 'i-e949',
          c: '#42DB44',
        });
      }
    },
    getWinningWayText() {
      return filters.winningWayText(this.winningWay, this.komi, this.boardSize);
    },
    hideReviewHand() {
      if (this.suggestPathMove) {
        this.wgoHelper.removeMarkup({
          x: this.suggestPathMove.x,
          y: this.suggestPathMove.y,
          type: 'M',
        });
        this.wgoHelper.setFrozen(false);
        this.wgoHelper.dropBranch();
        this.wgoHelper.setFrozen(true);
        this.suggestPathMove = null;
      }
      if (this.wgoHelper.showStepType === '') {
        this.wgoHelper.showStep('normal');
        this.$emit('step-type-change', this.wgoHelper.showStepType);
      }
    },
    addMarkup({path, type, text}) {
      this.wgoHelper.addMarkup({
        x: path.x,
        y: path.y,
        type,
        text,
      });
    },
    // 試下相關
    testPlay() {
      this.isTestPlay = !this.isTestPlay;
      this.$emit('is-test-play', this.isTestPlay);
      if (this.isTestPlay) {
        this.chessboardToolbarMode = 'testPlay';
      } else if (this.variation.length > 0) {
        this.chessboardToolbarMode = 'checkVariation';
      } else {
        this.chessboardToolbarMode = this.toolbar || 'checkKifu';
      }
      if (this.isTestPlay) {
        this.wgoHelper.setFrozen(false);
        this.wgoHelper.setEditable(true);
        this.testPlayState = this.wgoHelper.startTestPlay();
        this.wgoHelper.showStep('all');
      } else {
        if (this.mode === 'checkKifu' || this.toolbar === 'checkKifu') {
          this.wgoHelper.setFrozen(true);
          this.wgoHelper.setEditable(false);
        }
        this.wgoHelper.endTestPlay(this.testPlayState);
        this.testPlayState = null;
        if (this.variation.length > 0) {
          this.wgoHelper.showStep('all');
        } else {
          this.wgoHelper.showStep('normal');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .chessboard {
  width: 100%;
  height: 100%;
  canvas {
    left: 0;
  }
}
.chessboard-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 768px) {
    width: calc(100vh - 56px - 48px);
    margin: 0 auto;
  }
  .chessboard-container {
    position: relative;
  }
}
.board-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.chessboard-toolbar.fixed-height {
  min-height: 38px;
}
.modal-judgement-result .card {
  border-width: 3px;
}
.isCorrect {
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
  z-index: 300;
  animation: exterior 0.4s 0.04s;
  span {
    font-size: 150px;
    animation: icono 0.6s 0.08s;
  }
  .icon-Colorful-correct-gray .path2,
  .icon-Colorful-correct .path2,
  .icon-Colorful-error .path2 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon-Colorful-correct-gray .path2::before,
  .icon-Colorful-correct .path2::before,
  .icon-Colorful-error .path2::before {
    margin-left: 0;
  }
  @keyframes exterior {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes icono {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.4);
    }
    75% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
