/* eslint-disable no-array-constructor */
/* eslint-disable require-jsdoc */

function sgfToNumber(sgfMove) {
  if (sgfMove.includes('B') || sgfMove.includes('W')) {
    const color = sgfMove[0] == 'B' ? 1 : -1;
    const x = sgfMove.charCodeAt(2) - 97;
    const y = sgfMove.charCodeAt(3) - 97;
    return {x, y, color};
  } else {
    const x = sgfMove.charCodeAt(0) - 97;
    const y = sgfMove.charCodeAt(1) - 97;
    return {x, y};
  }
}

/**
 * 落子擺棋譜
 * @param {Array} ownership 1d地盤矩陣
 * @param {String} player 玩家
 * @param {Number} boardSize 棋盤大小
 * @return {Array} 2d地盤矩陣
 */
function getInfluenceInfo(ownership, player = 'black', boardSize = 19) {
  let i = 0;
  const len = ownership.length;
  const influence = ownership;
  const influenceList = [];
  const influenceConfidence =
    influence.reduce((acum, val) => acum + Math.abs(val)) / influence.length;
  while (i < len) {
    /* TODO：轉換成前端棋譜形式*/
    if (influence[i] >= 0.6) {
      influenceList.push('O');
    } else {
      if (influence[i] <= -0.6) {
        influenceList.push('X');
      } else {
        influenceList.push('.');
      }
    }
    // influence[i] = influence[i].toFixed(2);
    i += 1;
  }

  const influenceArray = new Array([]);
  for (let j = 0; j < boardSize; j++) {
    influenceArray[j] = new Array([]);
    for (let k = 0; k < boardSize; k++) {
      if (player === 'black') {
        influenceArray[j][k] = influence[j * boardSize + k].toFixed(1);
      } else {
        influenceArray[j][k] = -influence[j * boardSize + k].toFixed(1);
      }
    }
  }
  return {influenceArray, influenceList, influenceConfidence};
}

/**
 * 落子擺棋譜
 * @param {Array} influence 1d地盤矩陣
 * @param {String} player 玩家
 * @param {Number} boardSize 棋盤大小
 * @return {Array} 2d地盤矩陣
 */
function getInfluenceArray(influence, player = 'black', boardSize = 19) {
  const influenceArray = new Array([]);
  for (let j = 0; j < boardSize; j++) {
    influenceArray[j] = new Array([]);
    for (let k = 0; k < boardSize; k++) {
      if (player === 'black') {
        influenceArray[j][k] = influence[j * boardSize + k].toFixed(1);
      } else {
        influenceArray[j][k] = -influence[j * boardSize + k].toFixed(1);
      }
    }
  }
  return influenceArray;
}

function loadSgf(sgf) {
  const obj = {};

  const sgfinfo = [
    'TE',
    'AN',
    'AP',
    'BR',
    'BT',
    'CP',
    'DT',
    'EV',
    'FF',
    'GM',
    'GN',
    'HA',
    'KM',
    'ON',
    'OT',
    'PB',
    'PC',
    'PL',
    'PW',
    'RE',
    'RO',
    'RU',
    'SO',
    'SZ',
    'TM',
    'US',
    'WR',
    'WT',
  ];
  // 擷取SGF資訊
  sgfinfo.forEach((element) => {
    const tmp = sgf.match(new RegExp(element + '\\[(.*?)\\]', 'i'));
    if (tmp) {
      obj[element] = tmp[1];
    }
  });

  // 擷取SGF落子
  const moves = sgf.split(';');
  for (let i = 0; i < moves.length; i++) {
    if (moves[i].search(/^[B|W]\[(.*?)\]/)) {
      moves.splice(i--, 1);
      continue;
    }
    moves[i] = moves[i].substring(0, 5);
  }
  obj['moves'] = moves;
  obj['handicap'] = getHandicap(sgf);

  return obj;
}

function getGroupAndLiberties(influenceArray, position, player) {
  const group = []; // 被認定是同一塊棋的座標{x, y}陣列
  const liberties = [];
  const pending = [position]; // 等待存取的座標{x, y}陣列
  const boardArray = influenceArray;
  if (player === 'black') {
    boardArray[position.y][position.x] = 'X';
  } else {
    boardArray[position.y][position.x] = 'O';
  }
  const mark = boardArray[position.y][position.x];
  const isContain = (arr, x, y) => arr.some((d) => d.x === x && d.y === y);
  while (pending.length > 0) {
    const {x, y} = pending.pop();
    if (boardArray[y][x] === mark) {
      group.push({x, y});
      if (
        y - 1 >= 0 &&
        !isContain(pending, x, y - 1) &&
        !isContain(group, x, y - 1)
      ) {
        pending.push({x, y: y - 1});
      }
      if (
        y + 1 < boardArray.length &&
        !isContain(pending, x, y + 1) &&
        !isContain(group, x, y + 1)
      ) {
        pending.push({x, y: y + 1});
      }
      if (
        x - 1 >= 0 &&
        !isContain(pending, x - 1, y) &&
        !isContain(group, x - 1, y)
      ) {
        pending.push({x: x - 1, y});
      }
      if (
        x + 1 < boardArray[y].length &&
        !isContain(pending, x + 1, y) &&
        !isContain(group, x + 1, y)
      ) {
        pending.push({x: x + 1, y});
      }
    } else if (
      ['o', 'x', '.', 'I'].includes(boardArray[y][x]) &&
      !isContain(liberties, x, y)
    ) {
      liberties.push({x, y});
    }
  }
  return {liberties, group};
}

function removeDeadStones(goArray, numberMove, boardSize, player) {
  let neighbors = [
    {x: numberMove.x + 1, y: numberMove.y},
    {x: numberMove.x - 1, y: numberMove.y},
    {x: numberMove.x, y: numberMove.y + 1},
    {x: numberMove.x, y: numberMove.y - 1},
  ];
  neighbors = neighbors
    .filter((n) => n.x >= 0 && n.x < boardSize)
    .filter((n) => n.y >= 0 && n.y < boardSize);
  for (const neighbor of neighbors) {
    if ((goArray[neighbor.y][neighbor.x] === 'O') & (player === 'black')) {
      const {liberties, group} = getGroupAndLiberties(
        goArray,
        neighbor,
        'white'
      );
      if (liberties.length === 1) {
        for (const stone of group) {
          goArray[stone.y][stone.x] = '.';
        }
      }
    } else if (
      (goArray[neighbor.y][neighbor.x] === 'X') &
      (player === 'white')
    ) {
      const {liberties, group} = getGroupAndLiberties(
        goArray,
        neighbor,
        'black'
      );
      if (liberties.length === 1) {
        for (const stone of group) {
          goArray[stone.y][stone.x] = '.';
        }
      }
    }
  }
  return goArray;
}

function isPointAnEye(goArray, numberMove, boardSize) {
  let player = '';
  let playerTag;
  if (
    goArray[numberMove.y][numberMove.x] !== 'I' &&
    goArray[numberMove.y][numberMove.x] !== '.'
  ) {
    return false;
  }

  let neighbors = [
    {x: numberMove.x + 1, y: numberMove.y},
    {x: numberMove.x - 1, y: numberMove.y},
    {x: numberMove.x, y: numberMove.y + 1},
    {x: numberMove.x, y: numberMove.y - 1},
  ];
  neighbors = neighbors
    .filter((n) => n.x >= 0 && n.x < boardSize)
    .filter((n) => n.y >= 0 && n.y < boardSize);
  for (const neighbor of neighbors) {
    if (
      goArray[neighbor.y][neighbor.x] === '.' &&
      goArray[neighbor.y][neighbor.x] === 'I'
    ) {
      return false;
    }
    if (player.length === 0) {
      player = goArray[neighbor.y][neighbor.x] === 'X' ? 'black' : 'white';
      playerTag = goArray[neighbor.y][neighbor.x];
    }
    if (goArray[neighbor.y][neighbor.x] !== playerTag) {
      return false;
    }
  }

  const diagonalPoints = [
    {x: numberMove.x + 1, y: numberMove.y + 1},
    {x: numberMove.x - 1, y: numberMove.y - 1},
    {x: numberMove.x + 1, y: numberMove.y - 1},
    {x: numberMove.x - 1, y: numberMove.y + 1},
  ];

  let friendlyPoints = 0;
  let outsidePoints = 0;
  for (const diagonalPoint of diagonalPoints) {
    if (
      diagonalPoint.y < 0 ||
      diagonalPoint.y >= boardSize ||
      diagonalPoint.x < 0 ||
      diagonalPoint.x >= boardSize
    ) {
      outsidePoints += 1;
      continue;
    }
    if (goArray[diagonalPoint.y][diagonalPoint.x] === playerTag) {
      friendlyPoints += 1;
    }
  }

  if (outsidePoints > 0) {
    return outsidePoints + friendlyPoints >= 4;
  } else {
    return friendlyPoints >= 3;
  }
}

function isPointForbidden(goArray, numberMove, boardSize, player) {
  const opponent = player == 'black' ? 'white' : 'black';
  const playerTag = player == 'black' ? 'X' : 'O';

  let neighbors = [
    {x: numberMove.x + 1, y: numberMove.y},
    {x: numberMove.x - 1, y: numberMove.y},
    {x: numberMove.x, y: numberMove.y + 1},
    {x: numberMove.x, y: numberMove.y - 1},
  ];
  neighbors = neighbors
    .filter((n) => n.x >= 0 && n.x < boardSize)
    .filter((n) => n.y >= 0 && n.y < boardSize);
  for (const neighbor of neighbors) {
    if (goArray[neighbor.y][neighbor.x] === '.') {
      return false;
    } else if (goArray[neighbor.y][neighbor.x] === playerTag) {
      const {liberties} = getGroupAndLiberties(goArray, neighbor, player);
      if (liberties.length > 1) {
        return false;
      }
    } else {
      const {liberties} = getGroupAndLiberties(goArray, neighbor, opponent);
      if (liberties.length === 1) {
        return false;
      }
    }
  }
  return true;
}

function isPointOnKo(goArray, numberMove, boardSize, player) {
  const opponent = player == 'black' ? 'white' : 'black';
  const playerTag = player == 'black' ? 'X' : 'O';

  let neighbors = [
    {x: numberMove.x + 1, y: numberMove.y},
    {x: numberMove.x - 1, y: numberMove.y},
    {x: numberMove.x, y: numberMove.y + 1},
    {x: numberMove.x, y: numberMove.y - 1},
  ];
  neighbors = neighbors
    .filter((n) => n.x >= 0 && n.x < boardSize)
    .filter((n) => n.y >= 0 && n.y < boardSize);

  let count = 0;

  for (const neighbor of neighbors) {
    if (goArray[neighbor.y][neighbor.x] === '.') {
      return false;
    } else if (goArray[neighbor.y][neighbor.x] === playerTag) {
      return false;
    } else {
      const {group, liberties} = getGroupAndLiberties(
        goArray,
        neighbor,
        opponent
      );
      if (liberties.length === 1 && group.length === 1) {
        count += 1;
      }
    }
  }

  return count === 1;
}

function isGroupAlive(goArray, numberMove, boardSize) {
  if (
    goArray[numberMove.y][numberMove.x] === '.' &&
    goArray[numberMove.y][numberMove.x] === 'I'
  ) {
    return false;
  }
  const player =
    goArray[numberMove.y][numberMove.x] === 'X' ? 'black' : 'white';

  let eyeCount = 0;

  const {liberties} = getGroupAndLiberties(goArray, numberMove, player);
  for (const liberty of liberties) {
    if (isPointAnEye(goArray, liberty, boardSize)) {
      eyeCount += 1;
    }
  }
  return eyeCount >= 2;
}

function getNewGoArray(goArray, numberMove, boardSize, player) {
  const opponent = player == 'black' ? 'white' : 'black';
  const newGoArray = [
    ['.', '.', '.'],
    ['.', '.', '.'],
    ['.', '.', '.'],
  ];
  for (let i = 0; i < boardSize; i++) {
    for (let j = 0; j < boardSize; j++) {
      const testPoint = {x: i, y: j};
      if (goArray[j][i] === 'I') {
        if (!isPointForbidden(goArray, testPoint, boardSize, opponent)) {
          newGoArray[testPoint.y][testPoint.x] = '.';
        }
      } else {
        newGoArray[testPoint.y][testPoint.x] =
          goArray[testPoint.y][testPoint.x];
      }
    }
  }

  let neighbors = [
    {x: numberMove.x + 1, y: numberMove.y},
    {x: numberMove.x - 1, y: numberMove.y},
    {x: numberMove.x, y: numberMove.y + 1},
    {x: numberMove.x, y: numberMove.y - 1},
  ];
  neighbors = neighbors
    .filter((n) => n.x >= 0 && n.x < boardSize)
    .filter((n) => n.y >= 0 && n.y < boardSize);
  for (const neighbor of neighbors) {
    if ((goArray[neighbor.y][neighbor.x] === 'O') & (player === 'black')) {
      const {liberties, group} = getGroupAndLiberties(
        goArray,
        neighbor,
        'white'
      );
      if (liberties.length === 1) {
        for (const stone of group) {
          newGoArray[stone.y][stone.x] = '.';
        }
      }
    } else if (
      (goArray[neighbor.y][neighbor.x] === 'X') &
      (player === 'white')
    ) {
      const {liberties, group} = getGroupAndLiberties(
        goArray,
        neighbor,
        'black'
      );
      if (liberties.length === 1) {
        for (const stone of group) {
          newGoArray[stone.y][stone.x] = '.';
        }
      }
    }
  }
  newGoArray[numberMove.y][numberMove.x] = player === 'black' ? 'X' : 'O';

  for (const neighbor of neighbors) {
    if (
      newGoArray[neighbor.y][neighbor.x] === '.' &&
      goArray[neighbor.y][neighbor.x] !== '.'
    ) {
      if (
        isPointForbidden(newGoArray, neighbor, boardSize, opponent) ||
        isPointOnKo(newGoArray, neighbor, boardSize, opponent)
      ) {
        newGoArray[neighbor.y][neighbor.x] = 'I';
      }
    }
  }

  for (let i = 0; i < boardSize; i++) {
    for (let j = 0; j < boardSize; j++) {
      if (newGoArray[j][i] === '.') {
        const testPoint = {x: i, y: j};
        if (isPointForbidden(newGoArray, testPoint, boardSize, opponent)) {
          newGoArray[testPoint.y][testPoint.x] = 'I';
        }
      }
    }
  }
  return newGoArray;
}

function getValidMoves(boardArray, boardSize = 3) {
  const validMoves = [];
  for (let i = 0; i < boardSize; i++) {
    for (let j = 0; j < boardSize; j++) {
      if (boardArray[j][i] === '.') {
        const numberMove = {x: i, y: j};
        validMoves.push(numberMove);
      }
    }
  }
  return validMoves;
}

function removeDeadStonesAndCount(goArray, numberMove, boardSize, player) {
  const removeStones = [];
  let neighbors = [
    {x: numberMove.x + 1, y: numberMove.y},
    {x: numberMove.x - 1, y: numberMove.y},
    {x: numberMove.x, y: numberMove.y + 1},
    {x: numberMove.x, y: numberMove.y - 1},
  ];
  neighbors = neighbors
    .filter((n) => n.x >= 0 && n.x < boardSize)
    .filter((n) => n.y >= 0 && n.y < boardSize);
  for (const neighbor of neighbors) {
    if ((goArray[neighbor.y][neighbor.x] === 'O') & (player === 'black')) {
      const {liberties, group} = getGroupAndLiberties(
        goArray,
        neighbor,
        'white'
      );
      if (liberties.length === 1) {
        for (const stone of group) {
          goArray[stone.y][stone.x] = '.';
          removeStones.push(stone);
        }
      }
    } else if (
      (goArray[neighbor.y][neighbor.x] === 'X') &
      (player === 'white')
    ) {
      const {liberties, group} = getGroupAndLiberties(
        goArray,
        neighbor,
        'black'
      );
      if (liberties.length === 1) {
        for (const stone of group) {
          goArray[stone.y][stone.x] = '.';
          removeStones.push(stone);
        }
      }
    }
  }
  return {goArray, removeStones};
}

/**
 * 棋盤座標轉SGF座標
 * @param {String} boardMove - 棋盤座標 ex. A19
 * @param {Number} boardSize - [可選] 棋盤長寬
 * @returns {String} - SGF座標 ex. B[aa]
 */
function boardToSgf(boardMove, boardSize = 19) {
  let xAscii = boardMove.substring(0, 1).toLowerCase().charCodeAt();
  if (xAscii >= 105) xAscii--; // 因為棋盤座標沒有 i
  const xChar = String.fromCharCode(xAscii);
  const yAscii = Number(boardSize) - Number(boardMove.substring(1)) + 97;
  const yChar = String.fromCharCode(yAscii);

  return `${xChar}${yChar}`;
}

/**
 * 從sgf檔中得到起始棋子
 * @param {String} sgf 棋譜sgf檔
 * @param {Array} moves 起始棋子的矩陣
 * @returns {Array} 起始棋子的矩陣
 */
function addInitialStone(sgf, moves) {
  const isInitialStone = function (str = '') {
    const result = str.match(/^.*[a-z]+.*$/);
    if (result == null) return false;
    return true;
  };

  const isWhiteHandicapExisted = sgf.includes('AW[');
  let whiteHandicapIndex = sgf.indexOf('AW[');
  let isEndOfSearch = false;
  if (isWhiteHandicapExisted) {
    while (!isEndOfSearch) {
      const sgfMove = sgf.slice(whiteHandicapIndex + 3, whiteHandicapIndex + 5);
      if (!isInitialStone(sgfMove[0])) break;
      const move = 'W[' + sgfMove + ']';
      moves.unshift(move);
      whiteHandicapIndex += 4;
    }
  }

  const isBlackHandicapExisted = sgf.includes('AB[');
  let blackHandicapIndex = sgf.indexOf('AB[');
  isEndOfSearch = false;
  if (isBlackHandicapExisted) {
    while (!isEndOfSearch) {
      const sgfMove = sgf.slice(blackHandicapIndex + 3, blackHandicapIndex + 5);
      if (!isInitialStone(sgfMove[0])) break;
      const move = 'B[' + sgfMove + ']';
      moves.unshift(move);
      blackHandicapIndex += 4;
    }
  }

  return moves;
}

function getHandicap(sgf) {
  const initialStones = {
    black: [],
    white: [],
  };
  const isInitialStone = function (str = '') {
    const result = str.match(/^.*[a-z]+.*$/);
    if (result == null) return false;
    return true;
  };

  const isWhiteHandicapExisted = sgf.includes('AW[');
  let whiteHandicapIndex = sgf.indexOf('AW[');
  let isEndOfSearch = false;
  if (isWhiteHandicapExisted) {
    while (!isEndOfSearch) {
      const sgfMove = sgf.slice(whiteHandicapIndex + 3, whiteHandicapIndex + 5);
      if (!isInitialStone(sgfMove[0])) break;
      initialStones.white.push(sgfMove);
      whiteHandicapIndex += 4;
    }
  }

  const isBlackHandicapExisted = sgf.includes('AB[');
  let blackHandicapIndex = sgf.indexOf('AB[');
  isEndOfSearch = false;
  if (isBlackHandicapExisted) {
    while (!isEndOfSearch) {
      const sgfMove = sgf.slice(blackHandicapIndex + 3, blackHandicapIndex + 5);
      if (!isInitialStone(sgfMove[0])) break;
      initialStones.black.push(sgfMove);
      blackHandicapIndex += 4;
    }
  }
  return initialStones;
}

/**
 * 落子擺棋譜
 * @param {String} sgf sgf棋譜紀錄
 * @param {Boolean} skipLastMove 是否紀錄最後一手
 * @returns {Array} 棋盤矩陣
 */
function sgfToArray(sgf, skipLastMove = false) {
  let moves = sgf
    .split(';')
    .filter((d) => d.substring(0, 2) === 'B[' || d.substring(0, 2) === 'W[');
  moves = moves.filter((m) => m.length > 4);
  if (moves.length > 0) {
    moves[moves.length - 1] = moves[moves.length - 1].slice(0, -1); // 將SGF最後一個move會伴隨的括號去掉
  }
  // moves[moves.length - 1] = moves[moves.length - 1].slice(0, -1); // 將SGF最後一個move會伴隨的括號去掉
  moves = addInitialStone(sgf, moves);

  let goArray = new Array();
  const sgfContent = loadSgf(sgf);
  const boardSize = sgfContent.SZ;

  for (let j = 0; j < boardSize; j++) {
    goArray[j] = new Array();
    for (let k = 0; k < boardSize; k++) {
      goArray[j][k] = '.';
    }
  }
  let moveNum = 0;
  for (const move of moves) {
    moveNum += 1;
    if (moveNum === moves.length && skipLastMove) {
      break;
    }
    const letter = 'abcdefghijklmnopqrs';
    if (letter.includes(move[3])) {
      const numberMove = sgfToNumber(move);
      const player = numberMove.color === 1 ? 'black' : 'white';
      goArray = removeDeadStones(goArray, numberMove, boardSize, player);
      goArray[numberMove.y][numberMove.x] = numberMove.color === 1 ? 'X' : 'O';
    }
  }
  return goArray;
}

/**
 *
 * @param {String} sgf sgf棋譜紀錄
 * @param {String} move (ex: df or D6)
 * @return {Boolean} 是否是可下的位置
 */
function isValidMove(sgf, move) {
  if (move === '' || move === 'pass' || move === 'resign') {
    return true;
  }
  const boardSize = loadSgf(sgf).SZ;
  // move形式轉換
  const num = /[0-9]/;
  if (num.test(move)) {
    move = boardToSgf(move, boardSize);
  }

  if (move.search(/^[a-s]{2}$/) === -1) {
    return false;
  }

  const moves = sgf
    .split(';')
    .filter((d) => d.substring(0, 2) === 'B[' || d.substring(0, 2) === 'W[');
  if (moves.length === 0) {
    return true;
  }

  const player =
    sgf.lastIndexOf('B') > sgf.lastIndexOf('W') ? 'white' : 'black';
  const playerSgf = player === 'white' ? 'W' : 'B';
  const pastGoArray = sgfToArray(sgf, true);
  const lastMove = sgf
    .split(';')
    .reverse()
    .find((d) => d.substring(0, 2) === 'B[' || d.substring(0, 2) === 'W['); // B[]
  if (lastMove.length < 5) {
    return true;
  }
  const lastNumberMove = sgfToNumber(lastMove);
  const opponent = player == 'black' ? 'white' : 'black';

  // 前一手的吃子情況
  const {goArray, removeStones} = removeDeadStonesAndCount(
    pastGoArray,
    lastNumberMove,
    boardSize,
    opponent
  );
  goArray[lastNumberMove.y][lastNumberMove.x] =
    opponent === 'white' ? 'O' : 'X';
  const numberMove = sgfToNumber(playerSgf + '[' + move + ']');
  if (goArray[numberMove.y][numberMove.x] !== '.') {
    return false;
  }
  // 這一手的吃子情況
  const newGoInfo = removeDeadStonesAndCount(
    goArray,
    numberMove,
    boardSize,
    player
  );
  const newGoArray = newGoInfo.goArray;
  const newRemoveStones = newGoInfo.removeStones;
  newGoArray[numberMove.y][numberMove.x] = player === 'white' ? 'O' : 'X';
  const {liberties, group} = getGroupAndLiberties(
    newGoArray,
    numberMove,
    player
  );
  // 確認是否是自殺
  if (liberties.length === 0) {
    return false;
  }
  // 確認是否是打劫而無法提吃
  // 新落的棋子與其他我方棋子不相連且提吃對方一子且提吃完剩一氣
  if (
    liberties.length === 1 &&
    group.length === 1 &&
    newRemoveStones.length === 1
  ) {
    // 上一手在旁邊
    if (
      Math.abs(lastNumberMove.y - numberMove.y) +
        Math.abs(lastNumberMove.x - numberMove.x) ===
      1
    ) {
      const lastGroupAndLiberites = getGroupAndLiberties(
        goArray,
        lastNumberMove,
        opponent
      );
      const lastLiberties = lastGroupAndLiberites.liberties;
      const lastGroup = lastGroupAndLiberites.group;
      // 上一手同新落的棋子：與其他我方棋子不相連且提吃對方一子且提吃完剩一氣
      if (
        lastLiberties.length <= 1 &&
        lastGroup.length === 1 &&
        removeStones.length === 1
      ) {
        if (
          newRemoveStones[0].x === lastNumberMove.x &&
          newRemoveStones[0].y === lastNumberMove.y
        ) {
          return false;
        }
      }
    }
  }

  return true;
}

/**
 * 從sgf落子擺棋譜並算氣
 * @param {String} sgf sgf棋譜紀錄
 * @return {Array} 棋盤氣數矩陣
 */
function sgfToLibertiesArray(sgf) {
  const goArray = sgfToArray(sgf);

  const libertiesArray = new Array();
  const sgfContent = loadSgf(sgf);
  const boardSize = sgfContent.SZ;
  for (let j = 0; j < boardSize; j++) {
    libertiesArray[j] = new Array([]);
    for (let k = 0; k < boardSize; k++) {
      libertiesArray[j][k] = '.';
    }
  }

  for (let i = 0; i < goArray.length; i++) {
    for (let j = 0; j < goArray[i].length; j++) {
      if (goArray[i][j] === '.') {
        libertiesArray[i][j] = 0;
      } else {
        const numberMove = {x: j, y: i};
        const player = goArray[i][j] === 'O' ? 'white' : 'black';
        const libertiesNum = getGroupAndLiberties(goArray, numberMove, player)
          .liberties.length;
        libertiesArray[i][j] = libertiesNum;
      }
    }
  }
  return libertiesArray;
}

export {
  sgfToArray,
  isValidMove,
  sgfToLibertiesArray,
  getInfluenceInfo,
  loadSgf,
  getHandicap,
  getGroupAndLiberties,
  getInfluenceArray,
  getValidMoves,
  getNewGoArray,
  isPointForbidden,
  sgfToNumber,
  isPointAnEye,
  isGroupAlive,
};
