<template>
  <div class="sticker-sheet">
    <action-sheet
      v-if="$device.isMobile"
      ref="action-sheet"
      title=""
      sheet-height=""
      @close="close"
    >
      <div class="pt-3 pb-2 px-2 w-100 h-100">
        <div class="d-flex row mx-0">
          <div
            v-for="sticker in stickers"
            :key="sticker.value"
            class="col-4 mb-2 px-1"
          >
            <div
              class="sticker-wrapper rounded-10 text-center"
              :class="{'jungo-sticker-wrapper': $isJungo}"
              @click="select(sticker.value)"
            >
              <img
                class="w-100"
                :src="
                  require('@/assets/img/sticker/' +
                    sticker.value +
                    '.png?srcset')
                "
              />
            </div>
          </div>
        </div>
      </div>
    </action-sheet>
    <div v-if="!$device.isMobile" class="web-sticker-sheet">
      <div class="wrap-stickers-close text-primary text-16" @click.stop="close">
        <span v-if="!$isJungo"> {{ $t('收起') }}</span>
        <span class="icon-Chevron-down"></span>
      </div>
      <div class="pb-md-3 px-md-3">
        <div class="pt-2 pb-0 px-1 w-100 wrap-sticker-content scroll-bar">
          <div class="d-flex row mx-0 w-100">
            <div
              v-for="sticker in stickers"
              :key="sticker.value"
              class="sticker mb-2 px-1"
              :class="{'col-4': $device.isMobile}"
            >
              <div
                class="sticker-wrapper rounded-10 text-center"
                :class="{'jungo-sticker-wrapper': $isJungo}"
                @click.stop="select(sticker.value)"
              >
                <img
                  class="w-100"
                  :src="
                    require('@/assets/img/sticker/' +
                      sticker.value +
                      '.png?srcset')
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionSheet from './ActionSheet.vue';
export default {
  components: {
    ActionSheet,
  },
  data() {
    return {
      stickers: [
        {value: 1},
        {value: 2},
        {value: 3},
        {value: 4},
        {value: 5},
        {value: 6},
        {value: 7},
        {value: 8},
        {value: 9},
      ],
    };
  },
  methods: {
    select(value) {
      this.$emit('select', value);
      this.$device.isMobile
        ? this.$refs['action-sheet'].closeSheet()
        : this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .sticker-wrapper {
  border: 1px solid $line-2;
  &.jungo-sticker-wrapper {
    border-color: $font-grayscale-1;
  }

  @media screen and (min-width: 768px) {
    background: white;
    cursor: pointer;
  }
}
.web-sticker-sheet {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  border-radius: 10px;
  left: 0;
  .wrap-sticker-content {
    background: $grayscale-5;
    border-radius: 6px;
    width: 100%;
    height: 253px;
    max-height: 253px;
    overflow: overlay;
  }
  .sticker {
    @media screen and (min-width: 768px) {
      min-width: 120px;
      max-width: 50%;
    }
    @media screen and (min-width: 1200px) {
      min-width: 120px;
      max-width: 33.333%;
    }
  }
}
.wrap-stickers-close {
  line-height: 28px;
  box-shadow: 0px -4px 4px 0px #0000000a;
  text-align: center;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  .icon-Chevron-down {
    font-size: 24px;
    position: relative;
    top: 4px;
  }
}
</style>
